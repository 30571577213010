import { createApp, h, defineComponent, reactive, computed } from "vue";
import axios from "axios";
import ReLoginOverlay from "~/src/components/common/layout/ReLoginOverlay";
import { configureVue } from "~/src/util/setup-vue";

const TAB_ID = `${Math.random().toString(36)}${Math.random().toString(36)}`;

const auth = reactive({
  requiredRole: null,
  inited: false,
  userId: null,
  error: null,
  loading: false,
  name: null,
  email: null,
  role: null,
  rights: [],
  lastAuthEmail: null,
  needs2FA: null,
});
const loggedIn = computed(() => auth.userId !== null && !auth.needs2FA);
const loggingIn = computed(() => auth.loading);
const safeName = computed(() => auth.name || "");
const reLoginVisible = computed(() => {
  return (
    auth.inited && !loggedIn.value && !auth.needs2FA && !!auth.lastAuthEmail
  );
});

function setRequiredRole({ role }) {
  auth.requiredRole = role;
}

function hasRight(right) {
  if (right === "passport_read") {
    return (
      auth.userId !== null &&
      auth.rights &&
      (auth.rights.indexOf("passport_read") > -1 ||
        auth.rights.indexOf("passport_management") > -1 ||
        auth.rights.indexOf("passport_management_destructive") > -1 ||
        auth.rights.indexOf("all") > -1)
    );
  }
  return (
    auth.userId !== null &&
    auth.rights &&
    (auth.rights.indexOf(right) > -1 || auth.rights.indexOf("all") > -1)
  );
}

function wrapLoginAxiosRequest(axiosRequest, ignoreErrors = false) {
  return axiosRequest
    .then((response) => {
      if (response.data.id) {
        if (auth.requiredRole === response.data.role) {
          setStateLogin({
            userId: response.data.id.toString(),
            role: response.data.role,
            rights: response.data.rights,
            name: response.data.name,
            email: response.data.email,
            needs2FA: response.data.needs2FA,
          });
        } else {
          throw new Error(
            `You are role ${response.data.role} but this login is for ${auth.requiredRole}`,
          );
        }
      } else {
        console.error(response);
        if (!ignoreErrors) {
          setStateLoginFailure({
            error: "cannot handle response",
          });
        } else {
          abortLogin();
        }
        throw new Error("login error");
      }
    })
    .catch((error) => {
      if (!ignoreErrors) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setStateLoginFailure({
            error: error.response.data.error,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setStateLoginFailure({
            error: error.message || error,
          });
        }
      } else {
        abortLogin();
      }
      throw new Error("login error");
    });
}
function startLogin() {
  auth.loading = true;
}
function abortLogin() {
  auth.loading = false;
}
function setStateLogin({ userId, role, rights, name, email, needs2FA }) {
  auth.inited = true;
  auth.role = role;
  auth.rights = rights;
  auth.userId = userId.toString();
  auth.name = name;
  auth.email = email;
  auth.error = null;
  auth.loading = false;
  auth.lastAuthEmail = email;
  auth.needs2FA = needs2FA;
  notifyAuthChange();
}
function setStateLoginFailure({ error }) {
  auth.role = null;
  auth.rights = [];
  auth.userId = null;
  auth.name = null;
  auth.email = null;
  auth.error = error;
  auth.loading = false;
  auth.lastAuthEmail = null;
  auth.needs2FA = null;
  notifyAuthChange();
}
function setStateAuthLost() {
  auth.role = null;
  auth.rights = [];
  auth.userId = null;
  auth.name = null;
  auth.email = null;
  auth.error = null;
  auth.loading = false;
  auth.needs2FA = null;
  notifyAuthChange();
}
function markAsInited() {
  auth.inited = true;
  notifyAuthChange();
}
function getHeaders() {
  const csrfToken = (document.querySelector("meta[name=csrf-token]") || {})
    .content;
  const headers = {
    "X-Env": location.host,
  };
  if (csrfToken) {
    headers["X-CSRF-Token"] = csrfToken;
  }
  return headers;
}
function logout() {
  if (!loggedIn.value) {
    return Promise.resolve(false);
  }
  startLogin();
  auth.lastAuthEmail = null;
  return axios
    .delete("/users/sign_out", {
      headers: {
        ...getHeaders(),
      },
      responseType: "json",
    })
    .finally(() => {
      setStateAuthLost();
    });
}
function authenticate({ email, password }) {
  startLogin();
  return wrapLoginAxiosRequest(
    axios.post(
      "/users/sign_in",
      {
        user: {
          email,
          password,
        },
      },
      {
        headers: {
          ...getHeaders(),
        },
        responseType: "json",
      },
    ),
  );
}
function provide2FA({ code }) {
  startLogin();
  return wrapLoginAxiosRequest(
    axios.post(
      "/users/provide_2fa",
      {
        user: {
          code,
        },
      },
      {
        headers: {
          ...getHeaders(),
        },
        responseType: "json",
      },
    ),
  );
}
function refreshSession(ignoreErrors = false) {
  startLogin();
  return wrapLoginAxiosRequest(
    axios.post(
      "/users/refresh",
      {},
      {
        headers: {
          ...getHeaders(),
        },
        responseType: "json",
      },
    ),
    ignoreErrors,
  );
}

export const ReactiveAuth = {
  inited: computed(() => auth.inited),
  error: computed(() => auth.error),
  loading: computed(() => auth.loading),
  needs2FA: computed(() => auth.needs2FA),
  userId: computed(() => auth.userId),
  loggedIn,
  loggingIn,
  safeName,
  reLoginVisible,
  setRequiredRole,
  authenticate,
  provide2FA,
  hasRight,
  logout,
  hasRightEmployeeManagement: computed(() => hasRight("employee_management")),
  hasRightPassportRead: computed(() => hasRight("passport_read")),
  hasRightPassportManagement: computed(() => hasRight("passport_management")),
  hasRightPassportManagementDestructive: computed(() =>
    hasRight("passport_management_destructive"),
  ),
  hasRightStatistics: computed(() => hasRight("statistics")),
};

const AuthApp = configureVue(
  createApp(
    defineComponent({
      name: "AuthApp",
      components: { ReLoginOverlay },
      // data() {
      //   return auth;
      // },
      setup(props) {
        return () => {
          const { lastAuthEmail, needs2FA, error } = auth;
          if (reLoginVisible.value) {
            return h("div", { class: "auth auth__re-login" }, [
              h(ReLoginOverlay, {
                visible: true,
                email: lastAuthEmail,
                error: error,
                needs2FA: needs2FA,
                doAuthenticate: authenticate,
                do2fa: provide2FA,
              }),
            ]);
          }
          return h("div", { class: "auth" });
        };
      },
    }),
  ),
);

const rootEl = document.createElement("div");
document.body.appendChild(rootEl);
AuthApp.mount(rootEl);

export function init() {
  return refreshSession(true)
    .then(() => true)
    .catch((err) => {
      markAsInited();
      return false;
    });
}

let authChangeCallbacks;

export function onAuthChange(callback) {
  if (!authChangeCallbacks) {
    authChangeCallbacks = [];
  }
  authChangeCallbacks.push(callback);
  return function removeCallback() {
    authChangeCallbacks.splice(authChangeCallbacks.indexOf(callback), 1);
  };
}

function notifyAuthChange() {
  if (authChangeCallbacks) {
    for (let i = 0; i < authChangeCallbacks.length; i++) {
      authChangeCallbacks[i](ReactiveAuth);
    }
  }
}

export const AuthPlugin = {
  install(app, options) {
    app.config.globalProperties.$auth = ReactiveAuth;
  },
};
